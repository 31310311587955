import React from 'react';
import styles from './imageHeader.module.css';
import '../../styles/index.css';

function imageHeader({title}) {
  return (
    <header className={styles.imageHeaderContainer}>
        <div className="container" style={{alignItems: 'flex-start'}}>
            <div className={styles.boxImgText}>
                <p>{title}</p>
            </div>
        </div>
        <img src={'https://polygon.thememove.com/data/images/section/bg-header.jpg'} alt="" className={styles.imgCont} />
    </header>
  );
}

export default imageHeader;