import React from "react";
import classNames from 'classnames';

import HeaderImage from '../components/imageHeader';

import Layout from "../components/layout";

import '../styles/index.css';
import styles from '../styles/consulting.module.css';
import { FaCheckCircle } from "react-icons/fa";
import img from '../assets/images/fusoes-e-aquisicoes/image.png'

const FusoesAquisicoesPage = () => {

    return (
        <Layout titlePage="VENDA DE EMPRESA – M&A">

            <HeaderImage title="VENDA DE EMPRESA – M&A" />

            <div className={classNames("container", styles.consultingContainer)}>
                <main>

                    <section className={styles.divHeaderText}>
                        <h4 className={styles.title}>FUSÕES E AQUISIÇÕES - M&A</h4>

                        <p className={styles.description}>Trabalhamos com assessoria em fusões e aquisições para empresas de diversos segmentos e tamanhos. Temos um histórico de sucesso em transações com fundos de investimentos, empresas estrangeiras e empresas locais.</p>
                        <p className={styles.description}>Desde a nossa fundação, em 2010, temos criado valor para nossos clientes através de trabalhos estruturados, conduzidos por profissionais com amplo conhecimento dos segmentos em que atuam e de negociação.</p>
                        <p className={styles.description}>Nossos profissionais possuem metodologia com comprovado sucesso de trabalho. Manteremos sob completo sigilo as informações de sua empresa até a confirmação do interesse do potencial investidor e assinatura de acordos de confidencialidade.</p>
                        <p className={styles.description}>Efetuamos todas as etapas do processo de venda. Iniciamos com o valuation, passando pela busca de potenciais interessados, a estruturação de material para análise da operação, coordenação da due diligence e negociação de contratos de compra e venda. Converse com a nossa equipe!</p>
                    </section>

                    {/* <section className={styles.divMainText}>
                        <h4 className={styles.title}>ASSESSORIA EM FUSÕES E AQUISIÇÕES – SETORES QUE JÁ ATUAMOS</h4>

                        <p className={styles.description}>Assessoramos empresas de diversos setores nos últimos anos, como:</p>
                        
                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Operadoras de saúde</p>
                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Laboratórios</p>
                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Indústrias</p>
                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Construtoras</p>
                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Startups</p>
                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Empresas de TI</p>
                        <p className={styles.description}><FaCheckCircle className={styles.icon} />Empresas de telecom</p>

                    </section> */}

                    <section className={styles.divMainText}>
                        <h4 className={styles.title}>Principais operações realizadas pela equipe da GlobalTrevo Consulting nos últimos anos</h4>

                        <figure className={styles.posFig}>
                            <img src={img} alt="Principais operações realizadas pela equipe da GlobalTrevo Consulting nos últimos anos"/>
                        </figure>
                    </section>
                    <div className="whatsAppLogo">
                        <a href="https://wa.me/5511976282854" title="Whatsapp" className='wppFixed' >
                            <img src="/wpp.svg" alt="" />
                        </a>
                    </div>
                </main>

            </div>
        </Layout>
    )
}

export default FusoesAquisicoesPage
